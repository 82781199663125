import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import uniqid from 'uniqid';
import {
  Block,
  FeaturedCard,
  Content,
  Carousel,
  Divider,
  Picture,
  Video,
  Contact,
  List,
  Grid,
  Cta,
  Dealers
} from '@partials';

export default class ModuleArea extends Component {
  getComponent(block, variant = '') {
    switch (block.model.apiKey) {
      case 'featured_card':
        return <FeaturedCard key={block.id} {...block} />;
      case 'content':
        return <Content key={block.id} {...block} />;
      case 'content_with_image':
        return <Content type={'images'} key={block.id} {...block} />;
      case 'content_with_carousel':
        return <Content type={'carousel'} key={block.id} {...block} />;
      case 'content_with_video':
        return <Content type={'video'} key={block.id} {...block} />;
      case 'divider':
        return <Divider key={block.id} {...block} />;
      case 'image':
        return <Picture key={block.id} {...block} standAlone={true} />;
      case 'video':
        return block.video ? (
          <Video key={block.id} {...block} standAlone={true} />
        ) : null;
      case 'icon_card':
        return <Cta key={block.id} {...block} />;
      case 'contact_form':
        const { state } = this.props.location;
        return (
          <Contact
            key={block.id}
            product={state && state.product}
            type={state && state.type}
            {...block}
          />
        );
      case 'carousel':
        return (
          <Carousel key={block.id} items={block.carouselItems} {...block} />
        );
      case 'dealer_list':
        return <Dealers key={block.id} {...block} />;
      case 'document_list':
        return <List key={block.id} items={block.documents} {...block} />;
      case 'document_group_list':
        return (
          <Grid
            key={block.id}
            {...block}
            headingLevel={3}
            moduleStyle="document-group-list"
          >
            {block.documentGroup.map(({ documents, id, groupName, image }) => (
              <List
                key={id}
                image={image}
                heading={groupName}
                headingLevel={4}
                items={documents}
                moduleStyle="document-group-list"
              />
            ))}
          </Grid>
        );
      default:
        return (
          <center>
            {`Module Type: `}
            <code>{block.model.apiKey}</code>
            {` is not supported.`}
          </center>
        );
    }
  }

  render() {
    const { modules } = this.props;

    return modules.map(block => {
      return (
        <Block
          key={uniqid()}
          padding={block.padding}
          background={block.background}
        >
          {this.getComponent(block)}
        </Block>
      );
    });
  }
}

ModuleArea.propTypes = {
  modules: PropTypes.array.isRequired
};

export const query = graphql`
  fragment ContentModuleArea on DatoCmsPage {
    content {
      ... on DatoCmsContent {
        ...Content
      }
      ... on DatoCmsImage {
        ...Picture
      }
      ... on DatoCmsVideo {
        ...Video
      }
      ... on DatoCmsDealerList {
        ...Dealers
      }
      ... on DatoCmsDivider {
        ...Divider
      }
      ... on DatoCmsContactForm {
        ...Contact
      }
      ... on DatoCmsDocumentGroupList {
        ...DocumentGroup
      }
      ... on DatoCmsDocumentList {
        ...DocumentsList
      }
      ... on DatoCmsFeaturedCard {
        ...FeaturedCard
      }
      ... on DatoCmsIconCard {
        ...Cta
      }
      ... on DatoCmsCarousel {
        ...Carousel
      }
    }
  }
`;
