import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { t } from '../helpers/language';
import ModuleArea from '../helpers/moduleArea';
import { FaPhone } from 'react-icons/fa';
import { Waypoint } from 'react-waypoint';
import {
  Intro,
  Section,
  Prefooter,
  ProductIntro,
  FeaturedCard,
  Swatches,
  Carousel,
  CardList,
  Card,
  Specs,
  Compare
} from '@partials';

import { PageTransition } from '../helpers/pageTransitions';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    };
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  };

  render() {
    const {
      data: { product, globalConfig },
      pageContext,
      path,
      location
    } = this.props;
    const compareProducts = product.productComparison;

    const { contactPage, wetTestPage, defaultPathways } = globalConfig;

    const sectionNav = [
      {
        text: t('overview_section_label'),
        id: `overview`
      },
      {
        text: t('design_section_label'),
        id: `features`
      },
      {
        text: t('specifications_section_label'),
        id: `specifications`
      },
      {
        text: t('compare_section_label'),
        id: `compare`
      }
    ];

    const overview = {
      heading: 'Overview',
      caption: product.overview,
      image: product.gallery,
      links: [
        {
          path: `${contactPage.path}`,
          gaTarget: 'product-enquiry-cta',
          state: {
            type: 'contact',
            product: product
          },
          title: t('vortex_contact_cta')
        },
        {
          path: `${wetTestPage.path}`,
          gaTarget: 'product-wet-test-cta',
          state: {
            type: 'wet-test',
            product: product
          },
          title: t('vortex_wet_test_cta')
        }
      ]
    };

    console.log(overview);

    const productFeatures = product.productFeatures.map(feature => ({
      ...feature,
      padding: 'Both',
      background: 'None'
    }));

    const allPathways = [...product.pathways, ...defaultPathways].slice(0, 3);

    const showColours =
      product.shellColours.length > 0 || product.cabinetColours.length > 0;

    if (showColours) {
      sectionNav.splice(1, 0, {
        text: t('colours_section_label'),
        id: `options`
      });
    }

    return (
      <PageTransition>
        <HelmetDatoCms seo={product.seoMetaTags} />
        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
          <div>
            <Section id="product-top">
              <Intro
                title={product.title}
                breadcrumbs={pageContext.breadcrumbs}
                action={{
                  children: t('price_cta'),
                  gaTarget: 'product-contact-us',
                  to: `/contact-us`
                }}
              ></Intro>
            </Section>
            <Section animate={true} padding={'double-top'}>
              <ProductIntro {...product} sectionNav={sectionNav} />
            </Section>

            <Section id="overview" animate={true}>
              <FeaturedCard {...overview} />
            </Section>
          </div>
        </Waypoint>

        {showColours && (
          <Section
            id="options"
            heading={t('colour_heading')}
            lead={product.coloursDescription}
            background={'primary'}
            padding={'double'}
            overflow
          >
            <Swatches
              image={product.heroImage}
              shellColours={product.shellColours}
              cabinetColours={product.cabinetColours}
            ></Swatches>
          </Section>
        )}

        <Section
          id="features"
          heading={`${product.title} Features`}
          lead={product.featuresDescription}
          padding={'double'}
        >
          {productFeatures && (
            <ModuleArea modules={productFeatures} location={location} />
          )}

          {product.featuresCarousel.length > 0 && (
            <Carousel moduleStyle="Features" items={product.featuresCarousel} />
          )}
        </Section>

        {allPathways.length === 3 && (
          <Section padding={'both'}>
            <CardList fullWidth={true}>
              {allPathways.map((pathway, i) => (
                <Card key={`pathway-${i}`} {...pathway} />
              ))}
            </CardList>
          </Section>
        )}

        <Section
          id="specifications"
          heading={t('specs_heading')}
          lead={product.specificationsDescription}
          padding={'both'}
        >
          <Specs {...product} />
        </Section>

        {product.productComparison && (
          <Section
            id="compare"
            heading={t('compare_heading')}
            lead={product.compareDescription}
            background="light-gray"
            padding={'double'}
          >
            <Compare
              product={product}
              compareProducts={compareProducts}
              contactPagePath={contactPage.path}
              path={path}
            />
          </Section>
        )}
        <Section animate={true}>
          <Prefooter />
        </Section>
      </PageTransition>
    );
  }
}

export const query = graphql`
  query productById($id: String!, $language: String!) {
    product: datoCmsProduct(id: { eq: $id }) {
      id
      slug
      ...InfusionsoftTags
      ...ProductIntro
      ...Specs

      seating
      lounge
      jets
      dimensions
      capacity
      price

      warrantyDocument {
        url
        alt
        title
      }

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      overview

      featuresDescription

      productFeatures {
        ... on DatoCmsContentWithImage {
          ...ContentWithImage
        }
        ... on DatoCmsContentWithVideo {
          ...ContentWithVideo
        }
        ... on DatoCmsContentWithCarousel {
          ...ContentWithCarousel
        }
      }

      coloursDescription

      ...Swatches

      compareDescription

      productComparison {
        ...Compare
      }

      featuresCarousel {
        ...Slide
      }
      gallery {
        fluid(
          maxWidth: 860
          maxHeight: 600
          imgixParams: { fit: "crop", w: "860", h: "600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }

      pathways {
        ...Card
      }
    }

    globalConfig: datoCmsConfig(locale: { eq: $language }) {
      contactPage {
        path
      }
      wetTestPage {
        path
      }
      defaultPathways {
        ...Card
      }
    }
  }
`;

export default Product;
